import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import VideoPlayCard from '~components/VideoPlayCard';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { formatDurationOfFirstVideoTishaBav } from '~utils/playlist-helpers';
import Container from '~components/Container';

const useStyles = makeStyles(theme => ({
  spotlightsArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    gap: '75px',
    [theme.breakpoints.down('xs')]: {
      gap: '32px'
    }
  },
  media: {
    aspectRatio: '16 / 9',
    borderRadius: 15,
    minHeight: '100px'
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '40px',
    textAlign: 'center',
    color: props => props.text_color || '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px'
    }
  },
  shapeDivider: {
    display: 'flex'
  },
  spotlight: props => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    width: props.compact_margins ? '100%' : '80%',
    [theme.breakpoints.down('xs')]: {
      width: props.compact_margins ? '100%' : '90%',
      gap: '16px'
    }
  }),
  container: {
    paddingTop: 15,
    paddingBottom: 15
  }
}));

const SpotlightPlaylists = ({
  subscription_level,
  background_color,
  text_color,
  bottom_shape_divider,
  compact_margins,
  spotlights,
  containerSize
}) => {
  const classes = useStyles({ text_color, compact_margins });
  const { session } = useUserContext();
  const { isAdmin, isSubscriber, isUserStateLoading } = session;

  const hideSection = useMemo(() => {
    const shouldHideUnlessAdmin = subscription_level === 'Admins' && !isAdmin;
    const shouldHideUnlessSubscriber = subscription_level === 'Subscribers' && !isSubscriber;
    const shouldHideUnlessFreeOrGuest = subscription_level === 'Free Users/Guests' && isSubscriber;
    const shouldHideUntilUserStateDetermined =
      subscription_level !== 'Everyone' && isUserStateLoading;
    return (
      shouldHideUnlessAdmin ||
      shouldHideUnlessSubscriber ||
      shouldHideUnlessFreeOrGuest ||
      shouldHideUntilUserStateDetermined
    );
  }, [isAdmin, isSubscriber, isUserStateLoading, subscription_level]);

  if (hideSection) {
    return null;
  }

  return (
    <Box bgcolor={background_color || 'inherit'} position="relative" width="100%">
      <Container maxWidth={containerSize} className={classes.container}>
        <div className={classes.spotlightsArea}>
          {spotlights.map(spotlight => (
            <div key={spotlight?.id} className={classes.spotlight}>
              {spotlight?.title && (
                <Typography component="h2" className={classes.title}>
                  {spotlight?.title}
                </Typography>
              )}

              <VideoPlayCard
                title={spotlight?.playlist_title}
                url={spotlight?.url}
                description={spotlight?.playlist_description}
                image={spotlight?.cover_image}
                author={spotlight?.author}
                videoCount={spotlight?.total_videos}
                isAudio={spotlight?.is_audio}
                duration={formatDurationOfFirstVideoTishaBav(spotlight?.first_video_duration)}
              />
            </div>
          ))}
        </div>
      </Container>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider
          src={bottom_shape_divider?.url}
          bgColor="transparent"
          className={classes.shapeDivider}
        />
      )}
    </Box>
  );
};

SpotlightPlaylists.propTypes = {
  subscription_level: PropTypes.string.isRequired,
  background_color: PropTypes.string,
  text_color: PropTypes.string,
  compact_margins: PropTypes.bool,
  bottom_shape_divider: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }),
  spotlights: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      uid: PropTypes.string,
      url: PropTypes.string,
      title: PropTypes.string,
      playlist_title: PropTypes.string,
      playlist_description: PropTypes.string,
      cover_image: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
      }),
      first_video_duration: PropTypes.number,
      total_videos: PropTypes.number,
      is_audio: PropTypes.bool,
      author: PropTypes.string
    })
  ).isRequired,
  containerSize: PropTypes.string
};

SpotlightPlaylists.defaultProps = {
  background_color: '',
  text_color: '',
  bottom_shape_divider: null,
  compact_margins: false,
  containerSize: 'lg'
};

export default SpotlightPlaylists;
